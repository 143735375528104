.recipeDetails {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 3rem;

  &Horyzontal {
    display: flex;
  }
}