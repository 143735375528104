.mainContent {
  display: flex;
  flex-direction: column;
  height: 100%;

  &Body {
    padding-bottom: 3rem;
    flex: 1;
    overflow-y: auto;
  }
}
